import React, { Fragment, lazy, Suspense } from "react"
import TeachersNavBar from "./navigation/TeachersNavBar"
import { Container } from "@material-ui/core"
import { Switch } from "react-router-dom"
import { Route } from "react-router-dom"
import useFetchTeacherData from "./hooks/useFetchTeacherData"
import FullPageSpinner from "./common/FullPageSpinner"
import ScrollToTop from "./ScrollToTop"
import YearschedulePublic from "./components/YearschedulePublic"

const Login = lazy(() => import("./components/Login"))
const Student = lazy(() => import("./components/Student"))
const Students = lazy(() => import("./components/Students"))
const LessonCards = lazy(() => import("./components/LessonCards"))
const LessonCard = lazy(() => import("./components/LessonCard"))
const YearSchedules = lazy(() => import("./components/YearSchedules"))
const Mail = lazy(() => import("./components/Mail"))
const Hours = lazy(() => import("./components/Hours"))
const Schedule = lazy(() => import("./components/Schedule"))
const AccountSettings = lazy(() => import("./components/AccountSettings"))
const ForgotPassword = lazy(() => import("./components/ForgotPassword"))

const TeachersApp = () => {
  useFetchTeacherData()
  return (
    <Fragment>
      <ScrollToTop />
      <TeachersNavBar />
      <Container>
        <Suspense fallback={<FullPageSpinner />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/students" component={Students} />
            <Route path="/students/:id" component={Student} />
            <Route exact path="/lessoncards" component={LessonCards} />
            <Route path="/lessoncards/:id" component={LessonCard} />
            <Route path="/yearschedules" component={YearSchedules} />
            <Route path="/email" component={Mail} />
            <Route path="/hours" component={Hours} />
            <Route exact path="/" component={Schedule} />
            <Route exact path="/accountsettings" component={AccountSettings} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route
              exact
              path="/yearschedule-public"
              component={YearschedulePublic}
            />
          </Switch>
        </Suspense>
      </Container>
    </Fragment>
  )
}

export default TeachersApp
