const mainTheme = {
  palette: {
    primary: {
      main: "#11161b",
    },
    secondary: {
      main: "#c89b68",
    },
    error: {
      main: "#d50000",
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#ff9100",
    },
    info: {
      main: "#00838f",
    },
  },
}

export default mainTheme
