import React from "react"
import { CircularProgress, Typography } from "@material-ui/core"

const Loading = ({ size, label }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={size || 40} color="secondary" />
      {label && (
        <Typography
          style={{ marginTop: 5 }}
          variant="caption"
          color="secondary"
        >
          {label}...
        </Typography>
      )}
    </div>
  )
}

export default Loading
