import React from "react"
import "./App.css"
import ManagersApp from "./ManagersApp"
import TeachersApp from "./TeachersApp"
import useStyles from "./themesAndStyles/globalStyles"
import Notification from "./common/Notification"
import Login from "./components/Login"
import FullPageSpinner from "./common/FullPageSpinner"
import useUser from "./hooks/useUser"
import useCheckAuth from "./hooks/useCheckAuth"
// import IdleTimer from "react-idle-timer"
// import auth from "./services/auth"
import { Switch, Route } from "react-router-dom"
import ForgotPassword from "./components/ForgotPassword"
import ResetPassword from "./components/ResetPassword"
import YearschedulePublic from "./components/YearschedulePublic"

function App() {
  const checkAuth = useCheckAuth()
  const { role } = useUser()
  useStyles()

  return (
    <div style={{ height: "calc(100% - 80px)" }}>
      {/* <IdleTimer timeout={1000 * 60 * 15} onIdle={auth.logout} /> */}
      {checkAuth === "loading" && <FullPageSpinner />}
      {!role && (
        <Switch>
          <Route path="/resetpassword/:token" component={ResetPassword} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/" component={Login} />
          <Route
            exact
            path="/yearschedule-public"
            component={YearschedulePublic}
          />
        </Switch>
      )}
      {role === "manager" && <ManagersApp />}
      {role === "teacher" && <TeachersApp />}
      <Notification />
    </div>
  )
}

export default App
