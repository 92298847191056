import React, { useState, Fragment, useEffect } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import YearScheduleTable from "./YearScheduleTable"
import ScrollFeedback from "./ScrollFeedback"
import Loading from "../common/Loading"
import http from "../services/http"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "95%",
    maxWidth: "1000px",
    margin: "auto",
  },

  scheduleWrapper: {
    background: "white",
  },
}))

const YearSchedules = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [yearSchedule, setYearSchedule] = useState([])

  const fetchYearSchedule = async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API}/yearschedule/noord`
    )
    setYearSchedule(data)
    setLoading(false)
  }

  useEffect(() => {
    if (!yearSchedule.length) {
      fetchYearSchedule()
    }
  }, [yearSchedule])

  return (
    <Fragment>
      <ScrollFeedback
        revealHeight={150}
        message={`Jaarplanning Drumschool Robin`}
      />
      <div className={classes.wrapper}>
        <Typography variant="h4" style={{ margin: "40px 0" }}>
          Jaarrooster Drumschool Robin
        </Typography>

        {loading ? (
          <Loading />
        ) : (
          <div className={classes.scheduleWrapper}>
            <YearScheduleTable schedule={yearSchedule} />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default YearSchedules
