import React from "react"
import { useScrollYPosition } from "react-use-scroll-position"
import { makeStyles, Typography, Slide } from "@material-ui/core"
import colors from "../themesAndStyles/colors"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "fixed",
    top: theme.spacing(3),
    right: theme.spacing(3),
    padding: theme.spacing(1),
    border: `solid 1px ${theme.palette.secondary.main}`,
    background: colors.wrapperbg,
    maxWidth: 300,
    borderRadius: 4,
    textAlign: "center",
    color: theme.palette.secondary.main,
    zIndex: 1
  }
}))

const ScrollFeedback = ({ message, revealHeight }) => {
  const classes = useStyles()
  const scrollHeight = useScrollYPosition()

  return (
    scrollHeight >= revealHeight && (
      <Slide in={scrollHeight >= revealHeight} timeout={500}>
        <div className={classes.wrapper}>
          <Typography variant="caption">{message}</Typography>
        </div>
      </Slide>
    )
  )
}

export default ScrollFeedback
