import React from "react"
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  makeStyles,
  Hidden
} from "@material-ui/core"
import { NotificationsTwoTone } from "@material-ui/icons"
import clsx from "clsx"
import { isSameWeek, format, parseISO } from "date-fns"
import { nl } from "date-fns/locale/"

const useStyles = makeStyles(theme => ({
  cell: {
    padding: 5
  },
  row: {
    height: 40
  },
  noteRow: {
    background: "#eceff1"
  },
  vacationRow: {
    background: "#fce4ec"
  },
  noteVacationRow: {
    background: "#ede7f6"
  },
  currentWeek: {
    borderLeft: `solid 2px ${theme.palette.success.main}`,
    borderRight: `solid 2px ${theme.palette.success.main}`
  }
}))

const YearScheduleTable = ({ schedule }) => {
  const classes = useStyles()

  const setClass = week => {
    if (week.note && week.weekType !== "A" && week.weekType !== "B")
      return classes.noteVacationRow
    if (week.weekType !== "A" && week.weekType !== "B")
      return classes.vacationRow
    if (week.note) return classes.noteRow
  }

  const isCurrentWeek = week => {
    return isSameWeek(new Date(), parseISO(week), {
      weekStartsOn: 1,
      locale: nl
    })
  }

  const setCurrentWeekClass = week => {
    if (isCurrentWeek(week)) return classes.currentWeek
  }

  return (
    <Table>
      <TableBody>
        {schedule.map(week => {
          return (
            <Tooltip
              placement="bottom"
              key={week.from}
              title={isCurrentWeek(week.from) ? "Huidige week" : ""}
            >
              <TableRow
                className={clsx(
                  setClass(week),
                  setCurrentWeekClass(week.from),
                  classes.row
                )}
                hover
                selected={isCurrentWeek(week.from)}
              >
                <TableCell className={classes.cell}>
                  <strong>{week.week}</strong>
                </TableCell>
                <Hidden smDown>
                  <TableCell className={classes.cell}>
                    {format(parseISO(week.from), "dd-LL-yyyy", {
                      locale: nl
                    })}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {format(parseISO(week.till), "dd-LL-yyyy", {
                      locale: nl
                    })}
                  </TableCell>
                </Hidden>
                <TableCell className={classes.cell}>
                  <strong>{week.weekType}</strong>
                </TableCell>
                <TableCell className={classes.cell}>
                  {week.note && (
                    <Tooltip
                      title={week.note}
                      leaveDelay={200}
                      placement="left"
                      interactive
                    >
                      <IconButton color="secondary" size="small">
                        <NotificationsTwoTone />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </Tooltip>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default YearScheduleTable
