import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Input from "../common/Input"
import LoadingButton from "../common/LoadingButton"
import styles from "../themesAndStyles/styles"
import drumsLogo from "../img/drumsLogo.svg"
import validateLogin from "../validation/validateLogin"
import http from "../services/http"
import { notify } from "../common/Notification"
import useAppContext from "../hooks/useAppContext"
import { NavLink } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loginwrapper: {
    margin: "auto",
    backgroundColor: "white",
    boxShadow: theme.shadows[10],
    width: "33%",
    minWidth: 270,
    maxWidth: 512,
    padding: theme.spacing(3),
    paddingTop: 50,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4,
    position: "relative",
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 12,
    "&:visited": {
      color: theme.palette.secondary.main,
    },
  },
  img: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: 100,
    marginLeft: "50%",
    transform: "translateY(-95%) translateX(calc(-50% - 20px))",
    filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5))",
  },
}))

const Login = () => {
  const { dispatch } = useAppContext()
  const [state, setState] = useState({ username: "", password: "" })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const classes = useStyles()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoginError(null)
    const errors = validateLogin(state)
    if (errors) return setErrors(errors)
    setErrors({})
    try {
      setLoading(true)
      const { data } = await http.post(
        `${process.env.REACT_APP_API}/auth/login`,
        state
      )
      setLoading(false)
      dispatch({ type: "setuser", payload: data })
    } catch (error) {
      setLoading(false)
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data
      )
        return setLoginError(error.response.data)
      else console.log(error)
      notify("Er ging iets mis", "error")
    }
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.loginwrapper}>
        <div className={classes.img}>
          <img src={drumsLogo} alt="Logo Drumschool" />
        </div>
        {loginError && <div className="errorpaper">{loginError}</div>}
        <form id="login" autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          <Input
            autoFocus
            type="email"
            name="username"
            id="username"
            autoComplete="email"
            label="Gebruikersnaam"
            error={errors.username}
            value={state.username}
            onChange={(e) => setState({ ...state, username: e.target.value })}
          />
          <Input
            autoComplete="current-password"
            type="password"
            name="password"
            id="password"
            label="Wachtwoord"
            error={errors.password}
            value={state.password}
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />
          <LoadingButton
            type="submit"
            className={classes.btn}
            loading={loading}
            label="Login"
            variant={styles.mainBtn}
            color={styles.mainBtnColor}
          />
        </form>
        <NavLink
          to="/forgotpassword"
          variant="subtitle2"
          color="secondary"
          className={classes.link}
        >
          wachtwoord vergeten
        </NavLink>
      </div>
    </div>
  )
}

export default Login
