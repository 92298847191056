const colors = {
  option: "#eceff1",
  testlesson: "#e0f2f1",
  regularlesson: "#e0f2f1",
  lessoncard: "#e0f2f1",
  wrapperbg: "#fff",
  errorbg: "#ffebee",
  infobg: "#e8f5e9",
}

export default colors
// colors
